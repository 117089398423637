import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { WelcomeComponent } from '../welcome/welcome.component';

@Component({
  selector: 'app-iphonewelcome',
  templateUrl: './iphonewelcome.component.html',
  styleUrls: ['./iphonewelcome.component.sass']
})
export class IphonewelcomeComponent extends WelcomeComponent{
  
}
