import { Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';


@Component({
  selector: 'app-requestaninvite',
  templateUrl: './requestaninvite.component.html',
  styleUrls: ['./requestaninvite.component.sass']
})
export class RequestaninviteComponent {
  @Output() requestInfoProcessed = new EventEmitter<string>();
  orgName: any;
  email: any;
  message: string[] = [];
  mat_orgName = new FormControl('', [Validators.required]);
  mat_yourName = new FormControl('', [Validators.required]);
  mat_email = new FormControl('', [Validators.required, Validators.email]);
  mat_refererName = new FormControl('');
  isBusinessOwner = false;
  isHeardFromSomeone = false;
  requestSubmitted = false;
  protected aFormGroup: FormGroup;
  constructor(private router:Router, private http: HttpClient, private formBuilder: FormBuilder){
    console.log("in constructor..");
    this.aFormGroup = this.formBuilder.group({
      recaptcha: ['', Validators.required]
    });
  }
 
  public onSubmit(){
    this.requestSubmitted = true;
    this.message.push("Thank you");
    this.getAllErrorMessages();
    const errorsFnd = this.getAllErrorMessages();
    if(!errorsFnd){
      const verifyPromise = this.processInvReq();
      verifyPromise.subscribe((response: any) => {
        console.log(JSON.stringify(response));
        this.requestInfoProcessed.emit("inviterequestreceived");
      });
    }
    
  }

  getErrorMessage() {
    var result;
    this.message = [];
    if (this.mat_email.hasError('required')) {
      result = 'You must enter a value for Email';
    }
    else if (this.mat_email.hasError('email')) {
        result = 'Not a valid email';
    } 
    if (this.mat_orgName.hasError('required')) {
      result = 'You must enter a value for Organization Name';
    }
    if (this.mat_yourName.hasError('required')) {
      result = 'You must enter a value for your Name';
    }
    return result;
  }

  getAllErrorMessages() {
      var errorsFnd = false;
      this.message = [];
      if(!this.isBusinessOwner){
        this.message.push("Please select I own or manage a business check box");
        errorsFnd = true;
      }
      if (this.mat_orgName.hasError('required')) {
        this.message.push('A value for Organization Name is required');
        errorsFnd = true;
    }
    if (this.mat_yourName.hasError('required')) {
      this.message.push('A value for Your Name is required');
      errorsFnd = true;
    }
    if (this.mat_email.hasError('required')) {
      this.message.push('You must enter a value for Email');
      errorsFnd = true;
    }
    else if (this.mat_email.hasError('email')) {
      this.message.push('Not a valid email');
      errorsFnd = true;
    } 
    if(!errorsFnd){
      this.email = this.mat_email.value;
      this.orgName = this.mat_orgName.value;
    }
    const tokens = this.email.split("@");
    // const blockedDomains = "hotmail.com~aol.com~yahoo.com";
    const blockedDomains = "aol.com~yahoo.com";
    if(blockedDomains.includes(tokens[1])){
      errorsFnd = true;
      this.message.push(`Use of ${tokens[1]} is not allowed.`);
      this.message.push(`Please use your business email account.`);
    }
    return errorsFnd;
  }
   
  processInvReq(){
      var referer = this.mat_refererName.value ?? "";
      if(referer.length == 0){
        referer = "no referer";
      }
      var pl = {
        org: this.orgName,
        email: this.email,
        referer: referer
      };
      console.log("send invites pl:" + JSON.stringify(pl));
      const postreq = this.http.post('https://us-central1-ceeyu-bb46b.cloudfunctions.net/registernewevent', {
        headers:{
          header: 'Content-Type:application/json'
        }, 
        "payload": JSON.stringify(pl),
        "mode": "processinv"
      });
      return postreq;
  }
 
}
