import { Component } from '@angular/core';
import { IhaveaninviteComponent } from '../ihaveaninvite/ihaveaninvite.component';

@Component({
  selector: 'app-iphoneihaveaninvite',
  templateUrl: './iphoneihaveaninvite.component.html',
  styleUrls: ['./iphoneihaveaninvite.component.sass']
})
export class IphoneihaveaninviteComponent extends IhaveaninviteComponent{

}
