import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-thankyou',
  templateUrl: './thankyou.component.html',
  styleUrls: ['./thankyou.component.sass']
})
export class ThankyouComponent {
  public browserRefresh: boolean | undefined;
  @Input()
  public message = "";
  constructor() {}
}
