import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NgOptimizedImage } from '@angular/common';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.sass']
})
export class WelcomeComponent {
  public isIhaveAnInvite = false;
  public isIRequestInvite = false;
  public isThankYou = false;
  public message = "";
  received = "";
  form = new FormGroup({
    
  });
  iHaveAnInviteClicked(){
    this.isIhaveAnInvite = true;
    this.isIRequestInvite = false;
    this.isThankYou = false;
  }
  requestInvitation(){
    this.isIhaveAnInvite = false;
    this.isIRequestInvite = true;
    this.isThankYou = false;
  }

  onRequestInfoProcessed(eventData: string){
    console.log("received: " + eventData);
    this.isIhaveAnInvite = false;
    this.isIRequestInvite = false;
    this.isThankYou = true;
    switch (eventData){
      case "inviterequestreceived":
        this.message = "Thank you for your interest in Ceeyu.us. We will contact you with next steps.";
        break;
        case "validationcodereceived":
          this.message = "Validation code verified. We will contact you with next steps.";
          break;
    }
  }
}
