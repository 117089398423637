<div>
  <mat-checkbox color="primary"  [(ngModel)]="isBusinessOwner"  id="businessowner" name="bo">I own or manage a business</mat-checkbox>
</div>

<div>
    <mat-form-field hintLabel="Max 30 characters"  appearance="fill">
        <mat-label>Organization Name</mat-label>
        <input matInput #orgName maxlength="30" placeholder="Organization Name" [formControl]="mat_orgName" required>
        <mat-hint align="end">{{orgName.value.length}}/30</mat-hint>
        <mat-error *ngIf="mat_orgName.invalid">{{getErrorMessage()}}</mat-error>
    </mat-form-field>
</div>

<div class="mb-3">
  <mat-form-field hintLabel="Max 40 characters"  appearance="fill">
      <mat-label> Your Name</mat-label>
      <input matInput #yourName maxlength="40" placeholder="First Name Last Name" [formControl]="mat_yourName" required>
      <mat-hint align="end">{{yourName.value.length}}/40</mat-hint>
      <mat-error *ngIf="mat_yourName.invalid">{{getErrorMessage()}}</mat-error>
  </mat-form-field>
</div>

<div class="mb-3">
    <mat-form-field hintLabel="Max 140 characters"  appearance="fill">
      <mat-label>Enter your business email</mat-label>
      <input matInput #email placeholder="patrick@yourbusness.com" [formControl]="mat_email" required>
      <mat-hint align="end">{{email.value.length}}/140</mat-hint>
      <mat-error *ngIf="mat_email.invalid">{{getErrorMessage()}}</mat-error>
    </mat-form-field>
</div>
<div>
  &nbsp;
</div>
<div class="mb-3 w-90 text-danger">
  <li *ngFor="let msg of message">
    {{ msg }} 
  </li>
</div>
<div>
  <mat-checkbox color="primary"  [(ngModel)]="isHeardFromSomeone"  id="heardFromSomeone" name="heardFromSomeone">I heard about Ceeyu.us from someone.</mat-checkbox>
  <div *ngIf="isHeardFromSomeone" class="mb-3">
    <mat-form-field hintLabel="Max 60 characters" appearance="fill">
        <mat-label>Person you heard from</mat-label>
        <input matInput #referer maxlength="60" placeholder="First Name Last Name" [formControl]="mat_refererName">
        <mat-hint align="end">{{referer.value.length}}/60</mat-hint>
    </mat-form-field>
  </div>
</div>

<div>
  &nbsp;
</div>
<div class="mb-3">
    <button class="btn btn-primary" type="button" (click)="onSubmit()">Submit</button>
</div>
 