import { Component } from '@angular/core';
import { RequestaninviteComponent } from '../requestaninvite/requestaninvite.component';

@Component({
  selector: 'app-iphonerequestaninvite',
  templateUrl: './iphonerequestaninvite.component.html',
  styleUrls: ['./iphonerequestaninvite.component.sass']
})
export class IphonerequestaninviteComponent extends RequestaninviteComponent{

}
