<div class="d-none d-lg-block mt-20">
<div>
&nbsp;

</div>
<div class="container mx-auto mt-20 w-50 h-50">
    <div class="row">
        <div class="col-md"><img src="assets/Ceeyu.png" width="60" height="60"/></div>
        <div class="col-md">Thank you for visting Ceeyu.us website. New Laptop. </div>
      </div>
    
   
    <div class="mx-auto border-bottom row">
         <div class="row">Please note:</div>
         <div class="float-end row ">Our services are available only to few invited organizations.</div>
    </div>
    <form [formGroup]="form">
    <div class="mx-auto">
        <div class="float-start">
        <button
            type="button"
            class="btn btn-primary"
            (click)="iHaveAnInviteClicked()"
            [attr.aria-expanded]="isIhaveAnInvite"
            aria-controls="collapseExample"
        >
            I already have an Invite
        </button>
    </div>
    <div class="float-end">
        <button
            type="button"
            class="btn btn-primary ms-2"
            (click)="requestInvitation()"
            [attr.aria-expanded]="isIRequestInvite"
            aria-controls="collapseExample"
        >
           Please Invite Me
        </button>
    </div>
    
   <div>
    <div>
            <p class="text-center">
           &nbsp;
            </p>
    </div>
    <div>
        <p class="text-center">
       &nbsp;
        </p>
    </div>
    <div #collapse="ngbCollapse" [(ngbCollapse)]="!isIhaveAnInvite">
	    <div class="border border-info card float-left">
		    <div class="card-body">
               <app-ihaveaninvite (requestInfoProcessed)="onRequestInfoProcessed($event)"></app-ihaveaninvite>
            </div>
	    </div>
    </div>
    <div #collapse="ngbCollapse" [(ngbCollapse)]="!isIRequestInvite">
	    <div class="border border-info card float-right">
		    <div class="card-body">
                <app-requestaninvite (requestInfoProcessed)="onRequestInfoProcessed($event)"></app-requestaninvite>
            </div>
	    </div>
    </div>
    <div #collapse="ngbCollapse" [(ngbCollapse)]="!isThankYou">
	    <div class="border border-info card float-right">
		    <div class="card-body">
                <app-thankyou [message]="message"></app-thankyou>
            </div>
	    </div>
    </div>
   </div>
</div>
</form>
</div>
</div>
