import { Component, EventEmitter, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-ihaveaninvite',
  templateUrl: './ihaveaninvite.component.html',
  styleUrls: ['./ihaveaninvite.component.sass']
})
export class IhaveaninviteComponent {
  @Output() requestInfoProcessed = new EventEmitter<string>();
  orgName: any;
  invitationCode: any;
  message: string[] = [];
  mat_code = new FormControl('', [Validators.required]);
  mat_orgName = new FormControl('', [Validators.required]);
  isBusinessOwner = false;
  constructor(private http: HttpClient){
   
  }
  ngOnInit() {
    
  }
  
  onSubmit(){
    const errorsFnd = this.getAllErrorMessages();
    if(!errorsFnd){
      const verifyPromise = this.verifyInvitationCode();
      verifyPromise.subscribe((response: any) => {
        console.log(JSON.stringify(response));
        this.requestInfoProcessed.emit("validationcodereceived");
      });
    }
  }
  
  verifyInvitationCode(){
    this.message.push("Ok. Let me check the code. Please wait...");
    var pl = {
      org: this.orgName,
      code: this.invitationCode
    };
    console.log("send invites pl:" + JSON.stringify(pl));
    const postreq = this.http.post('https://us-central1-ceeyu-bb46b.cloudfunctions.net/registernewevent', {
      headers:{
        header: 'Content-Type:application/json'
      }, 
      "payload": JSON.stringify(pl),
      "mode": "chkinvcode"
    });
    return postreq;
  }

  getAllErrorMessages() {
    var errorsFnd = false;
    this.message = [];
    if(!this.isBusinessOwner){
      this.message.push("Please select I own or manage a business check box");
      errorsFnd = true;
    }
    if (this.mat_code.hasError('required')) {
      errorsFnd = true;
          this.message.push('A value for Invitation Code is required');
    }else{
      this.invitationCode = this.mat_code.value;
    }

    if (this.mat_orgName.hasError('required')) {
      errorsFnd = true;
       this.message.push('A value for Organization Name is required');
    }else{
      this.orgName = this.mat_orgName.value;
    }

    return errorsFnd;
  }

  getErrorMessage() {
    this.message = [];
    if (this.mat_code.hasError('required')) {
          return 'A value for Invitation Code is required';
    }

    else if (this.mat_orgName.hasError('required')) {
      return 'A value for Organization Name is required';
      
    }
    return "No errors found";
  }

 }
