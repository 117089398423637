import { Component } from '@angular/core';
import { ThankyouComponent } from '../thankyou/thankyou.component';

@Component({
  selector: 'app-iphonethankyou',
  templateUrl: './iphonethankyou.component.html',
  styleUrls: ['./iphonethankyou.component.sass']
})
export class IphonethankyouComponent extends ThankyouComponent {

}
