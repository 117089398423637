<div class="d-none d-lg-block mt-20">
  <div>&nbsp;</div>
  <div class="container mx-auto mt-20 w-50 h-50">
      <div class="row">
          <div class="col-md"><img src="assets/Ceeyu.png" width="60" height="60"/></div>
          <div class="col-md">Thank you for visting Ceeyu.us website. </div>
        </div>
      
     
      <div class="mx-auto border-bottom row">
           <div class="row">Please note:</div>
           <div class="float-end row ">Our services are available only to few invited organizations.</div>
      </div>
      
      <div>&nbsp;</div>
      <form #f="ngForm" [formGroup]="phoneForm">
        <div>If you are already registered in our database, then please enter your phone number.</div>
        <div>&nbsp;</div>
        <div>&nbsp;</div>
        <div class="wrapper">
          <ngx-intl-tel-input
            [cssClass]="'custom'"
            [preferredCountries]="preferredCountries"
            [enableAutoCountrySelect]="true"
            [enablePlaceholder]="true"
            [searchCountryFlag]="true"
            [searchCountryField]="[
              SearchCountryField.Iso2,
              SearchCountryField.Name
            ]"
            [selectFirstCountry]="false"
            [selectedCountryISO]="CountryISO.India"
            [maxLength]="15"
            [phoneValidation]="true"
            [separateDialCode]="separateDialCode"
            [numberFormat]="PhoneNumberFormat.National"
            name="phone"
            formControlName="phone"
          >
          </ngx-intl-tel-input>
        </div>
        <div>&nbsp;</div>
        <div>&nbsp;</div>
        <div>
            <button class="btn btn-primary" type="button" (click)="onSubmit()">Submit</button>
        </div>
      </form>
     
  </div>
  </div>
  