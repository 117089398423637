import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { WelcomeComponent } from './welcome/welcome.component';
import { IhaveaninviteComponent } from './ihaveaninvite/ihaveaninvite.component';
import { RequestaninviteComponent } from './requestaninvite/requestaninvite.component';
import { ThankyouComponent } from './thankyou/thankyou.component';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatFormFieldModule} from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatIconModule} from '@angular/material/icon';
import { HttpClientModule } from '@angular/common/http';
import { IphoneihaveaninviteComponent } from './iphoneihaveaninvite/iphoneihaveaninvite.component';
import { IphonerequestaninviteComponent } from './iphonerequestaninvite/iphonerequestaninvite.component';
import { IphonethankyouComponent } from './iphonethankyou/iphonethankyou.component';
import { IphonewelcomeComponent } from './iphonewelcome/iphonewelcome.component';
import { HomeComponent } from './home/home.component';
import {NgxIntlTelInputModule} from "ngx-intl-tel-input";
const routes: Routes = [
  {path: '', component: HomeComponent, pathMatch: 'full'},
  {path: 'home', component: HomeComponent, pathMatch: 'full'},
  {path: 'welcome', component: WelcomeComponent, pathMatch: 'full'},
  {path: 'ihaveinvite', component: IhaveaninviteComponent, pathMatch: 'full'},
  {path: 'requestinvite', component: RequestaninviteComponent, pathMatch: 'full'},
  {path: 'thankyou', component: ThankyouComponent, pathMatch: 'full'},
  {path: 'login', component: LoginComponent, pathMatch: 'full'}
];

@NgModule({
  declarations: [
    AppComponent,
    WelcomeComponent,
    IhaveaninviteComponent,
    RequestaninviteComponent,
    ThankyouComponent,
    IphoneihaveaninviteComponent,
    IphonerequestaninviteComponent,
    IphonethankyouComponent,
    IphonewelcomeComponent,
    HomeComponent,
    LoginComponent
  ],
  imports: [
    FormsModule, 
    ReactiveFormsModule,
    BrowserModule,
    AppRoutingModule,
    RouterModule.forRoot(routes),
    NgbModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatIconModule,
    HttpClientModule,
    NgxIntlTelInputModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
