<div>
    <mat-checkbox color="primary"  [(ngModel)]="isBusinessOwner"  id="businessowner" name="bo">I own or manage a business</mat-checkbox>
  </div>
  <div>
    <mat-form-field hintLabel="Max 30 characters"  appearance="fill">
        <mat-label>Organization Name</mat-label>
        <input matInput #orgName1 maxlength="30" placeholder="Organization Name" [formControl]="mat_orgName" required>
        <mat-hint align="end">{{orgName1.value.length}}/30</mat-hint>
        <mat-error *ngIf="mat_orgName.invalid">{{getErrorMessage()}}</mat-error>
    </mat-form-field>
  </div>
  
  <div>
    <mat-form-field hintLabel="Max 10 characters"  appearance="fill">
        <mat-label>Invitation Code</mat-label>
        <input matInput #code maxlength="10" placeholder="Invitation Code" [formControl]="mat_code" required>
        <mat-hint align="end">{{code.value.length}}/10</mat-hint>
        <mat-error *ngIf="mat_code.invalid">{{getErrorMessage()}}</mat-error>
    </mat-form-field>
  </div>
  <div>
    &nbsp;
  </div>
  <div class="text-danger">
    <li *ngFor="let msg of message">
      {{ msg }} 
    </li>
  </div>
  <div>
    &nbsp;
  </div>
  <div>
      <button class="btn btn-primary" type="submit" (click)="onSubmit()">Submit</button>
  </div>
  